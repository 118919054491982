'use client';

import Link from 'next/link';
import { routeNames } from '@/constants/routes';
import { navTags, navItems, contactUsLink } from '@/constants/navigations';
import { BurgerIcon, LogoBlack } from '@/images/svg';
import { HeaderLogo } from '@/images/svg/HeaderLogo';
import { NavLink } from '@/components/Layouts/NavLink';
import { ConicBackground } from '@/components/Backgrounds/Conic';
import { HeaderTag } from './HeaderTag';
import { usePathname, useRouter, useSelectedLayoutSegments } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';
import { determineDarkModeForRoute, getRouteSegments, is404, isCareersPages } from '@/helpers/darkMode';
import { setReferrerToLocalStorage } from '@/helpers/referrer';
import { useIsInViewport } from '@/hooks/useIsInViewport';
import { MobileNavOverlay } from '@/components/Layouts/Header/MobileNavOverlay';

export const Header = () => {
  const pathname = usePathname();
  const segments = useSelectedLayoutSegments();
  const router = useRouter();

  const [isMobileOpen, setMobileOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [isGreenMode, setIsGreenMode] = useState(true);
  const [isAnimationCalled, setAnimationCall] = useState(false);
  const [activeAnimation, setActiveAnimation] = useState(false);
  const collapsableContainer = useRef(null);
  const isInViewPort = useIsInViewport(collapsableContainer);
  const isPageNotFound = is404(segments, getRouteSegments(pathname));

  useEffect(() => {
    if (isInViewPort && !isAnimationCalled) {
      setAnimationCall(true);
      setActiveAnimation(true);
    }
  }, [isAnimationCalled, isInViewPort]);

  useEffect(() => {
    setMobileOpen(false);
  }, [pathname]);

  useEffect(() => {
    setReferrerToLocalStorage();
    setIsDarkMode(determineDarkModeForRoute(segments, getRouteSegments(pathname)));
    setIsGreenMode(isCareersPages(pathname));
  }, [isPageNotFound, pathname, segments]);

  const isContactPage = getRouteSegments(pathname).toString() === getRouteSegments(routeNames.contact).toString();

  const renderLogo = () => {
    if (isPageNotFound) {
      return (
        <div
          data-testid="header-logo"
          onClick={() => router.replace(routeNames.home)}
          className="block text-teal-600 cursor-pointer mb-[3px]"
        >
          {isDarkMode ? <HeaderLogo /> : <LogoBlack />}
        </div>
      );
    }

    return (
      <Link
        scroll={true}
        prefetch={false}
        href={routeNames.home}
        data-testid="header-logo"
        className="block text-teal-600"
      >
        {isDarkMode ? <HeaderLogo /> : <LogoBlack />}
      </Link>
    );
  };

  return (
    <>
      <header
        className={`
          fixed flex items-center z-100 t-0 l-0 w-full box-border h-[72px] mobile:h-[96px] backdrop-blur-[4px] 
          ${isDarkMode ? 'bg-black-11 bg-opacity-40' : 'border-b border-b-gray bg-white/30'}
        `}
        id="nav-header"
        ref={collapsableContainer}
      >
        <div className="container mx-auto w-full h-full">
          <div className="flex flex-wrap items-center h-full justify-between gap-y-xs">
            <div className="lg-1:flex lg-1:items-center lg-1:gap-3xl xl:flex-1 xl:gap-[106px]">
              {renderLogo()}

              <nav
                aria-label="Global"
                className="hidden lg-1:block"
              >
                <ul className="flex items-center gap-[6px] text-sm">
                  {navTags.map(({ label, url }) => (
                    <HeaderTag
                      key={url}
                      tagHref={`${url}`}
                      tagLabel={label}
                      testId={`header-nav-tag-${label}`}
                      classname={
                        isDarkMode
                          ? 'border-white text-white hover:border-primary'
                          : 'border-gray text-black-11 hover:border-primary'
                      }
                    />
                  ))}
                </ul>
              </nav>
            </div>

            <div className="lg-1:flex lg-1:items-center flex-row-reverse">
              <Link
                scroll={true}
                href={`${contactUsLink.url}`}
                className={`
                    isolate
                    transition-colors duration-300 ease-out border-opacity-50
                    relative rounded-full inline-flex items-center overflow-hidden
                    h-[36px] w-auto border pt-[8px] pb-xxs px-base group
                    border-white mb-[1px] hidden lg-1:block
                    ${isContactPage && 'invisible'}
                  `}
              >
                <p
                  data-testid="header-contact-us"
                  className={`
                      transition-colors duration-300 ease-out
                      mt-0 text-base leading-base relative
                      text-white
                    `}
                >
                  {contactUsLink.label}
                </p>

                <ConicBackground
                  classname={`
                    w-[300%] h-[350%] z-[-1]
                    ${!activeAnimation ? 'rotate-180' : ''}
                  `}
                />
              </Link>

              <nav
                aria-label="Global"
                className={`
                  hidden lg-1:block lg:mr-[40px]
                  [@media(min-width:1440px)]:mr-[125px]
                `}
              >
                <ul className="flex items-center gap-base text-sm">
                  {navItems.map(({ label, url }) => {
                    return (
                      <NavLink
                        key={url}
                        href={`${url}`}
                        classname={
                          isDarkMode
                            ? `h-[96px] text-sm border-transparent text-white hover:text-white hover:opacity-50
                            active:text-primary active:opacity-100`
                            : `h-[96px] text-sm border-transparent transition-all duration-300 ease-out text-black-11
                            hover:text-gray active:text-primary`
                        }
                        activeClassname={
                          isDarkMode ? '!border-white' : isGreenMode ? '!border-green-6' : '!border-primary'
                        }
                        testId={`header-nav-link-${label}`}
                      >
                        {label}
                      </NavLink>
                    );
                  })}
                </ul>
              </nav>

              <div className="flex items-center gap-base">
                <div className="block lg-1:hidden">
                  <button
                    onClick={() => {
                      setMobileOpen(true);
                    }}
                    className="rounded bg-gray-100 p-[8px] text-gray-600 transition hover:text-gray-600/75"
                  >
                    <BurgerIcon strokeColor={isDarkMode ? 'white' : 'currentColor'} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <MobileNavOverlay
        pathname={pathname}
        isOpen={isMobileOpen}
        setOpen={setMobileOpen}
        isContactPage={isContactPage}
      />
    </>
  );
};
