'use client';

import { footerConfig } from '@/constants/navigations';
import Link from 'next/link';
import { routeNames } from '@/constants/routes';
import { Logo } from '@/images/svg';
import { usePathname, useRouter, useSelectedLayoutSegments } from 'next/navigation';
import { getRouteSegments, is404 } from '@/helpers/darkMode';

export const Navigation = () => {
  const pathname = usePathname();
  const router = useRouter();
  const segments = useSelectedLayoutSegments();

  return (
    <div className="w-full mobile:w-[53.3%] flex flex-col">
      {is404(segments, getRouteSegments(pathname)) ? (
        <Logo
          className="mb-[32px] block mobile:hidden cursor-pointer"
          data-testid="footer-logo"
          onClick={() => router.replace(routeNames.home)}
        />
      ) : (
        <Link
          scroll={true}
          href={routeNames.home}
          data-testid="footer-logo"
          className="mb-[32px] block mobile:hidden"
        >
          <Logo />
        </Link>
      )}

      <ul className="flex flex-wrap gap-y-[32px] gap-x-[50px] mobile:gap-x-[23%]">
        {footerConfig.map(({ name, labels }) => {
          return (
            <li
              key={name}
              className="w-full max-w-[120px] mobile:w-auto mobile:max-w-full"
            >
              <p className="mb-[19px] text-white leading-[25.6px]">{name}</p>
              <ul>
                {labels.map(({ name, url }) => {
                  return (
                    <li
                      key={name}
                      className={`
                        mb-[16px] text-sm last:mb-0 transition text-white
                        hover:text-white50 active:text-primary font-semibold
                        `}
                    >
                      <Link
                        scroll={true}
                        href={`${url}` || ''}
                      >
                        {name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
