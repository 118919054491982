import(/* webpackMode: "eager" */ "/opt/gitlab-runner/builds/syberry-marketing/syberry-com-frontend-v2/node_modules/next-recaptcha-v3/lib/ReCaptcha.js");
import(/* webpackMode: "eager" */ "/opt/gitlab-runner/builds/syberry-marketing/syberry-com-frontend-v2/node_modules/next-recaptcha-v3/lib/ReCaptchaProvider.js");
import(/* webpackMode: "eager" */ "/opt/gitlab-runner/builds/syberry-marketing/syberry-com-frontend-v2/node_modules/next-recaptcha-v3/lib/useReCaptcha.js");
import(/* webpackMode: "eager" */ "/opt/gitlab-runner/builds/syberry-marketing/syberry-com-frontend-v2/node_modules/next-recaptcha-v3/lib/withReCaptcha.js");
import(/* webpackMode: "eager" */ "/opt/gitlab-runner/builds/syberry-marketing/syberry-com-frontend-v2/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/opt/gitlab-runner/builds/syberry-marketing/syberry-com-frontend-v2/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/opt/gitlab-runner/builds/syberry-marketing/syberry-com-frontend-v2/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/opt/gitlab-runner/builds/syberry-marketing/syberry-com-frontend-v2/src/app/globals.scss");
import(/* webpackMode: "eager" */ "/opt/gitlab-runner/builds/syberry-marketing/syberry-com-frontend-v2/node_modules/next/font/local/target.css?{\"path\":\"src/layouts/Root/index.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../../public/fonts/DMSans-Regular.ttf\",\"weight\":\"400\"},{\"path\":\"../../../public/fonts/DMSans-Medium.ttf\",\"weight\":\"500\"},{\"path\":\"../../../public/fonts/DMSans-SemiBold.ttf\",\"weight\":\"600\"}],\"variable\":\"--font-dmSans\"}],\"variableName\":\"dmSans\"}");
import(/* webpackMode: "eager" */ "/opt/gitlab-runner/builds/syberry-marketing/syberry-com-frontend-v2/node_modules/next/font/local/target.css?{\"path\":\"src/layouts/Root/index.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../../public/fonts/DMMono-Regular.ttf\",\"weight\":\"400\"},{\"path\":\"../../../public/fonts/DMMono-Medium.ttf\",\"weight\":\"500\"}],\"variable\":\"--font-dmMono\"}],\"variableName\":\"dmMono\"}");
import(/* webpackMode: "eager" */ "/opt/gitlab-runner/builds/syberry-marketing/syberry-com-frontend-v2/node_modules/next/font/local/target.css?{\"path\":\"src/layouts/Root/index.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../../public/fonts/DMMono-LightItalic.ttf\",\"weight\":\"300\"},{\"path\":\"../../../public/fonts/DMMono-Italic.ttf\",\"weight\":\"400\"},{\"path\":\"../../../public/fonts/DMMono-MediumItalic.ttf\",\"weight\":\"500\"}],\"variable\":\"--font-dmMono-italic\"}],\"variableName\":\"dmMonoItalic\"}");
import(/* webpackMode: "eager" */ "/opt/gitlab-runner/builds/syberry-marketing/syberry-com-frontend-v2/src/components/Layouts/Footer/Contact/index.tsx");
import(/* webpackMode: "eager" */ "/opt/gitlab-runner/builds/syberry-marketing/syberry-com-frontend-v2/src/components/Layouts/Footer/Navigation/index.tsx");
import(/* webpackMode: "eager" */ "/opt/gitlab-runner/builds/syberry-marketing/syberry-com-frontend-v2/src/components/Layouts/Header/index.tsx");
import(/* webpackMode: "eager" */ "/opt/gitlab-runner/builds/syberry-marketing/syberry-com-frontend-v2/src/images/socials/clutch.svg");
import(/* webpackMode: "eager" */ "/opt/gitlab-runner/builds/syberry-marketing/syberry-com-frontend-v2/src/images/socials/google.svg");
import(/* webpackMode: "eager" */ "/opt/gitlab-runner/builds/syberry-marketing/syberry-com-frontend-v2/src/images/socials/stars.svg")